<template>
  <div class="metadata-keywords-page">
    <keywords-header-tabs active="metadata" />

    <div class="metadata-keywords">
      <div class="recommended-table-wrap">
        <div class="new-container">

          <MetadataKeywordsTableReport
              :key="currentApp.id + currentCountry.code"

              :current-country="currentCountry"
              :current-app="currentApp"
              @tracking-keywords-changed="onTrackingChanged"
          ></MetadataKeywordsTableReport>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TheHeader from '@/components/TheHeader/index';
import {metadataKeywordsTableConfig} from "@/configs/page-tables-configs/metadata-keywords-table";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index";
import {getCookie} from "@/utils/utils";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import MetadataKeywordsTableReport from "./MetadataKeywordsTable.vue";
import HeaderTabs from "../../../components/UI/HeaderTabs/index.vue";
import KeywordsHeaderTabs from "@/views/SearchTerms/components/KeywordsHeaderTabs/index.vue";

export default {
  name: 'MetadataKeywords',
  components: {
    KeywordsHeaderTabs,
    HeaderTabs,
    MetadataKeywordsTableReport,
    'the-header': TheHeader,
    'progress-indicator': ProgressIndicator,
    'filters-dropdown': FiltersDropdown,
    'custom-dropdown': CustomDropdown,
  },
  data() {
    return {
      title: '',
      loaderText: '',
      headers: metadataKeywordsTableConfig.headers,
      searchAppInput: '',
      showKeywordsLoader: false,
      tabDropdownOpened: false,
    }
  },
  methods: {
    async onTrackingChanged() {
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      await this.fetchDataCounts();
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  async mounted() {
    await this.fetchDataCounts();
  },
  computed: {
    ...mapGetters([
      'pageMeta',
      'currentApp',
      'currentCountry',
      'promiseSettledData',
      'dataCounts',
      'dataCountsLoaded',
      'userSubscription',
    ]),
  },
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>